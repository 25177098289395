// Import global sass.
// > import sub sass files inside main.scss
import './assets/sass/main.scss';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';

//global variables
import config from './config';
import packagejson from '../package.json';

//dependable functions
import ComponentRepo from './services/ComponentRepo';
import AppInitializer from './services/AppInitializer';
import DataAdapter from './services/DataAdapter';
import CreateSiteManifestLoader from './services/FetchSiteManifest';
import store from './store/store';
import Preloader from './services/Preloader';
import ApiManager from './services/api/ApiManager';


// app data in global space
window.app = {
    version: packagejson.version,
    manifest: false,
    api: {},
    config
}



// Load manifest to create pseudo pages
const manifestLoader = CreateSiteManifestLoader({
    MANIFEST_PATH: config.manifest,
    FETCH_OPTIONS: {
        mode: "cors",
        headers: {
            "Accept-language": 'en'
        }
    },
    DataDecorator: (manifest) => {
        return DataAdapter(manifest)
    }
})

// Load manifest into global object for fast and easy access
window.app.manifest = manifestLoader;


// Init app
const vm = createApp(App).use(store);
ComponentRepo.setup(vm)
vm.use(store)
vm.use(router);
vm.mount('#app');


AppInitializer()
    // ------------- Add steps here to run during load of SPA -------------- //

    // initialize pre runtime api
    .addStep({
        id: 'api-manager',
        tasks: [{
            id: 'initialize-pre-apis',
            execute: () => ApiManager(app).initializePreApis()
        }
        ]
    })
    // initialize manifest fetching from endpoint
    .addStep({
        id: 'data-load',
        tasks: [{
            id: 'fetch-manifest',
            execute: () => manifestLoader.fetchData()
        }]
    })
    // preload visual assets such as images and video
    .addStep({
        id: 'preload',
        tasks: [{
            id: 'preload-backgrounds',
            execute: () => Preloader(app).preLoadBackgrounds()
        }, {
            id: 'preload-assets',
            execute: () => Preloader(app).preloadAssets()
        }]
    })
    // initialize post runtime api
    .addStep({
        id: 'api-manager',
        tasks: [{
            id: 'initialize-post-apis',
            execute: () => ApiManager(app).initializePostApis()
        }
        ]
    })
    .start(() => {
        // Start the app when above steps have been run
    })
    .then(() => {
        // Get video Transitions
        store.commit('storeVideoTransitions')

        //Check if page without content component exists or redirect to HomePage
        let route = window.app.vm.$route.path.split(":")[0];
        if (!app.api.PageManager.pageExists(route)) {
            //TODO:
            //Navigate til noget error 404 handling i stedet for homepage
            app.api.PageManager.navigateToHomePage().then(() => {
                store.commit('ready')
            })
        } else {
            store.commit('ready')
        }

        // Disable zooming on touch devices
        if ('ontouchstart' in window || navigator.maxTouchPoints) {
            document.addEventListener('touchmove', function (event) {
                if (event.scale !== 1) {
                    event.preventDefault();
                }
            }, { passive: false });
                        
            document.ondblclick = function(e) {
                e.preventDefault();
            }
        }
    }
)