<template>
  <nav id="UiLayer"  :style="[getMaximizedSizeAndOffset]">
      <Logo v-show="!hasLargeContentComponent"></Logo>
      <BackButton :style="dimUi"></BackButton>
      <FullscreenButton :style="dimUi"></FullscreenButton>
      <IdleToggleButton :style="dimUi"></IdleToggleButton>
      <BottomBar :style="dimUi"></BottomBar>
  </nav>
</template>
<script>
import Logo from "../ui/Logo.vue";
import BackButton from "../ui/BackButton.vue";
import FullscreenButton from "../ui/FullscreenButton.vue";
import IdleToggleButton from "../ui/IdleToggleButton.vue";
import BottomBar from "../ui/BottomBar.vue";

export default {
  components: {
    // Ui Components
    Logo,
    BackButton,
    FullscreenButton,
    IdleToggleButton,
    BottomBar
  },
  data() {
    return {
    };
  },
  methods: {
    
  },
  computed: {
    onHomePage() {
      this.$route;
      return app.api.PageManager.onHomePage();
    },
    hasLargeContentComponent(){
      this.$route
      if (!app.api.PageManager.getIsContentComponentOpen())
        return false
  
      var largeComponents = ["fullscreenImage", "fullscreenVideo"]
      var openComponents = app.api.PageManager.getOpenContentComponents()
      var i = 0;
  
      while (i < openComponents.length) {
        if (largeComponents.includes(openComponents[i].type)) {
          return true
        }
        i++
      }
    },
    dimUi() {
      if (this.hasLargeContentComponent) {
        return {
          opacity: 0.4,
          'pointer-events': 'none'
        }
      }
    },
    getMaximizedSizeAndOffset() {
      return this.$store.getters.getMaximizedSizeAndOffsetValue;
    },
  }
};
</script>

<style lang="scss">
#UiLayer {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1920px;
  height: 100%;
  pointer-events: none;

  * {
    pointer-events: all;
  }
}
</style>