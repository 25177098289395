<template>
    <transition name="HotspotEntrance">
        <button class="Hotspot HotspotDropdown" v-show="ready" :class="[getHotspotMutatorClasses, getHotspotTypeClass]" :style="[getPosition]">
            <div class="Hotspot__button" :style="[getAlternateHotspotIcon]" @click="toggleDropdown" :class="{'open': activeDropdown === hotspot.id}"></div>
            <span v-html="getHotspotTitle" @click="toggleDropdown"></span>
            <transition name="HotspotDropdown">
                <div v-show="activeDropdown === hotspot.id" class="HotspotDropdown__list">
                    <router-link v-for="hotspot in hotspotList" :to="hotspot.route" :key="hotspot.id"  @click="hotspotPressed">
                        {{hotspot.data.title}}
                    </router-link>
                </div>
            </transition>
        </button>
    </transition>
</template>

<script>

export default {
    props: ['hotspot', 'activeDropdown'],
    data() {
        return {
            ready: false,
            hotspotOpen: false,
            hotspotList: []
        }
    },
    created() {
        this.prepareHotspotList();
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        prepareHotspotList() {
            this.hotspotList = this.hotspot.children.map((hotspot, i) => {
                hotspot.route = this.getRoute(hotspot);
                return hotspot;
            })
        },
        getRoute(hotspot) {
            let hotspotReferenceID = Object.values(hotspot.data.type)[0];

            if(Object.keys(hotspot.data.type)[0] == 'scene') {
                return app.api.PageManager.getHotspotSceneRoute(hotspot.id);
            } else if (Object.keys(hotspot.data.type)[0] == 'content') {
                return app.api.PageManager.getCurrentRoute().split(":").slice(0)[0] + app.api.PageManager.getRouteFromIDs(null, hotspotReferenceID)
            } else {
                return '/'
            }
        },
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        },
        toggleDropdown() {
            this.activeDropdown === this.hotspot.id ? this.$emit('currentDropdown', null) : this.$emit('currentDropdown', this.hotspot.id);
        },
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"            

            switch(this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel` 

                // more types can be added here through the backend hotspotType data value.

                default: 
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getAlternateHotspotIcon() {
            if(this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        }
    }
}
</script>


<style lang="scss">
.HotspotDropdown {
    // uses styling from defaultHotspot for button & label

    .Hotspot__button{
        transition: rotate 0.2s ease;
        &.open { rotate: 45deg; }
    }

    &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    a {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--light-grey);
        font-size: 15px;
        height: 38px;
        padding: 0 26px;
        background: var(--main-color);
        border-bottom: 1px solid var(--light-grey);

        &:last-child { border-bottom: none; }
        &:hover { background-color: var(--secondary-color); }
    }

    /* dropdown animation */
    .HotspotDropdown-enter-active,
    .HotspotDropdown-leave-active {
        transition: all 0.4s var(--tk-easeinout);
    }
    .HotspotDropdown-enter-from {
        transform: translateY(-38px);
        opacity: 0;
    }
    .HotspotDropdown-leave-to {
        transform: translateY(-38px);
        opacity: 0;
    }
}
</style>