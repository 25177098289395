import { createStore } from 'vuex'

//  Create a new store instance.
const store = createStore({
    state() {
        return {
            ready: false,
            
            // Touchkit sizing variables
            scale: 1,
            backgroundWidth: 0,
            appResolution:{},
            maximizedSizeAndOffset:{},

            // Touchkit navigation variables
            allowNavigation: true,
            idleActive: true,
            isFullscreen: false,
            contentComponentOpen: false,
            videoTransitions: [],

            // Touchkit Pagescroller
            draggingBackground: false,
            draggingBackgroundStartPositions: [],

            // Touchkit AudioManager
            muted: false
        }
    },
    mutations: {
        ready(state) {
            state.ready = true;
        },
        setScaleValue(state, value) {
            state.scale = value;
        },
        setAppResolutionValue(state, value) {
            state.appResolution = value;
        },
        setMaximizedSizeAndOffsetValue(state, value) {
            state.maximizedSizeAndOffset = value;
        },
        enableNavigation(state) {
            state.allowNavigation = true;
        },
        disableNavigation(state) {
            state.allowNavigation = false;
        },
        toggleIdle(state) {
            state.idleActive = !state.idleActive
        },
        toggleFullscreen(state, bool) {
            state.isFullscreen = bool;
        },
        toggleContentComponentOpen(state, bool) {
            state.contentComponentOpen = bool;
        },
        storeVideoTransitions(state) {
            if(!app.api.PageManager.getCustomComponentFromType("videoTransitions"))
                return

            let umbracoTransitions = app.api.PageManager.getCustomComponentFromType("videoTransitions").data.transitions;
            let transitions = [];
        
            transitions = umbracoTransitions.map((transition, i) => {
              transition.id = `transition_${i}`;
              transition.from = transition.from ? app.api.PageManager.getRouteFromID(transition.from) : null;
              transition.to = transition.to ? app.api.PageManager.getRouteFromID(transition.to) : null;
              return transition;
            })
        
            state.videoTransitions = transitions;
        },

        // Touchkit Pagescroller
        setBackgroundWidth(state, value) {
            let width = value <= 1920 ? 1920 : value;
            state.backgroundWidth = width;
        },
        toggleDraggingBackground(state, bool) {
            state.draggingBackground = bool;
        },
        saveScrollPosition(state, value) {
            const existingIndex = state.draggingBackgroundStartPositions.findIndex(s => s.route === value.route);

            // If the object already exists, replace it at the existing index
            if (existingIndex !== -1) {
                state.draggingBackgroundStartPositions.splice(existingIndex, 1, value);

            // If it doesn't exist, push the new object
            } else {
                state.draggingBackgroundStartPositions.push(value);
            }
        },

        // Touchkit AudioManager
        toggleMute(state) {
            state.muted = !state.muted;

            if(state.muted) {
                app.api.AudioManager.mute()
            } else {
                app.api.AudioManager.unmute()
            }
        },
        mute(state) {
            state.muted = true;
            app.api.AudioManager.mute()
        },
        unmute(state) {
            state.muted = false;
            app.api.AudioManager.unmute()
        } 
    },
    getters: {
        ready(state) {
            return state.ready;
        },
        getScaleValue(state) {
            return state.scale;
        },
        getAppResolutionValue(state) {
            return state.appResolution;
        },
        getMaximizedSizeAndOffsetValue(state) {
            return state.maximizedSizeAndOffset;
        },
        canNavigate(state) {
            return state.allowNavigation;
        },
        getIdleState(state) {
            return state.idleActive
        },
        isFullscreen(state) {
            return state.isFullscreen;
        },  
        getContentComponentOpen(state) {
            return state.contentComponentOpen;
        },
        getVideoTransitions(state) {
            return state.videoTransitions;
        },

        // Touchkit Pagescroller
        getBackgroundWidth(state) {
            return state.backgroundWidth;
        },
        isBackgroundDragged(state) {
            return state.draggingBackground;
        },
        getSavedScrollPosition: (state) => (route) => {
            return state.draggingBackgroundStartPositions.find(s => s.route === route);
        },

        // Touchkit AudioManager
        getIsMuted(state) {
            return state.muted
        }
    },
    actions: {
        ready(context) {
            context.commit('ready')
        }
    }
})

export default store;
