<template>
    <nav class="ScrollNavigation">
        <div class="ScrollNavigation__wrapper" :style="[getNavPosition, getNavigationVisibility]">
            <button v-for="anchor in anchorPoints" @click="navigateToAnchor(anchor)" :key="anchor.position" :class="{'ScrollNavigation--active': anchor == activeAnchor}">
                ●
                <span>{{ anchor.title }}</span>
            </button>
        </div>
        <div class="ScrollNavigation__idle" :style="getIdleVisibility">
            <span>Drag mouse to navigate</span>
            <img src="@/assets/img/ui/sidescroll-idle.png">
            <span>You can move right or left</span>
        </div>
    </nav>
</template>
<script>
export default {
    props: ["anchorPoints", "activeAnchor", "activeAnchorIndex", "state"],
    data() {
        return {
            previousActiveAnchorIndex: null
        }
    },
    methods: {
        navigateToAnchor(anchor) {
            this.$emit("navigateToAnchor", anchor);
        }
    },
    computed: {
        getNavPosition() {
            let i = this.previousActiveAnchorIndex;


            if(this.activeAnchorIndex || this.activeAnchorIndex === 0) {
                i = this.activeAnchorIndex;
                this.previousActiveAnchorIndex = i;
            }

            return {
                transform: `translateX(-${i * 84}px)`
            };
        },
        getNavigationVisibility() {
            return {
                opacity: this.state !== "Idle" ? 1 : 0,
                'pointer-events': this.state === "Idle" ? "none" : "all"
            }
        },
        getIdleVisibility() {
            return {
                opacity: this.state === "Idle" ? 1 : 0
            }
        }
    }
}
</script>
<style lang="scss">
    
$navbuttonheight: 60px;
$navbuttonwidth: 60px;

.ScrollNavigation { 
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 135px;
    min-height: 135px;
    bottom: 70px;
    pointer-events: none !important;

    &__wrapper {
        display: flex;
        height: $navbuttonheight;
        width: $navbuttonwidth;
        position: relative;
        pointer-events: none;
        transition-property: transform opacity;
        transition-duration: 0.4s;
        transition-timing-function: var(--tk-easeinout);
    }

    button {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        margin-right: 24px;
        height: $navbuttonheight;
        min-width: $navbuttonwidth;
        cursor: pointer;
        pointer-events: inherit;

        span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            opacity: 0;
            top: -30px;
            transition: opacity 0.2s ease;
            font-weight: bold;
            color: var(--main-color);
        }

        &:hover {
            color: var(--main-color);
        }
        
        &.ScrollNavigation--active {
            color: var(--main-color);
            pointer-events: none;

            span {
                opacity: 1;
            }
        }

        &:last-of-type {
            margin-right: 0 !important;
        }
    }

    &__idle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        transition: opacity 0.4s ease;
        pointer-events: none !important;

        >img {
            margin: 0 20px;
        }

        > span {
            position: absolute;
            color: var(--main-color);
            font-weight: bold;
            white-space: nowrap;

            &:first-of-type {
                right: 100%;
            }

            &:last-of-type {
                left: 100%;
            }
        }
    }
}
</style>