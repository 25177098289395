<template>
    <div class="articleImage" @click="openFullscreenImage" :class="{'articleImage--hasFullscreen': content.contentLink}">
        <img :src="imageUrl">
    </div>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        openFullscreenImage() {
            if(!this.content.contentLink)
                return

            app.api.PageManager.openContentComponentFromID(this.content.contentLink, false)
        }
    },
    computed: {
        imageUrl() {
            return app.api.Utils.getMediaPath(this.content.image.src)
        }
    }
}
</script>
<style lang="scss">
.articleImage {
    position: relative;

    img {
        width: 100%;
    }

    &--hasFullscreen {
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            background-color: var(--main-color);
            background-image: url(@/assets/img/ui/fullscreen-icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }
}
</style>