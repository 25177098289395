<template>
    <h1 class="sceneHeading" v-html="properties.text"></h1>
  </template>
  
  <script>
  export default {
    props: [
      "properties",
      "pageData"
    ]
  };
  </script>
  
  <style lang="scss">
  .sceneHeading {
    position: absolute;
    left: 105px;
    top: 120px;
    color: #fff;
    font-weight: bold;
    font-size: 80px;
    text-align: left;
    line-height: 90px;
    text-shadow: rgba(2, 2, 1, 0.15) 0 0 8px;
    z-index: 2;
    user-select: none;
  }
  </style>