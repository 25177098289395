<template>
    <img src="@/assets/img/ui/bottom-bar.svg">
</template>

<style lang="scss" scoped>
    img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
</style>