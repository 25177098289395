<template>
    <div class="bodyText" v-html="content.text" ></div>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">

.bodyText {
    margin-bottom: 30px;

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }
}
</style>