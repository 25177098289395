<template>
  <div class="sceneGradient" :class="getGradientStyle"></div>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData"
  ],
  computed: {
    getGradientStyle() {
      let prefix = "sceneGradient";
      let color = "";
      let position = this.properties.gradientPosition;

      switch(this.properties.gradientColor) {
          case "#134094":
            color = "blue";
            break;
          case "#000000":
            color = "black";
            break;
      }

      return `${prefix}--${position} ${prefix}--${color}`
    }
  }
}
</script>

<style lang="scss">
.sceneGradient {
    position: absolute;
    pointer-events: none !important;

    &--top {
      left: 0;
      top: 0;
      width: 100%;
      height: 314px;

      &.sceneGradient--blue {
        background: linear-gradient(180deg, rgba(0,158,227, 0.18) 0%,rgba(0,158,227, 0) 100%);
        mix-blend-mode: multiply;
      }
      &.sceneGradient--black {
        background: linear-gradient(180deg, rgba(0,0,0, 0.3) 0%,rgba(84,84,84, 0) 100%);
      }
    }

    &--left {
      left: 0;
      top: 0;
      width: 1056px;
      height: 100%;

      &.sceneGradient--blue {
        background: linear-gradient(90deg, rgba(0,158,227, 0.18) 0%,rgba(0,158,227, 0) 100%);
        mix-blend-mode: multiply;
      }
      &.sceneGradient--black {
        background: linear-gradient(90deg, rgba(0,0,0, 0.3) 0%,rgba(84,84,84, 0) 100%);
      }
    }
}
</style>