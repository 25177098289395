<template>
  <p class="backgroundSlogan" :style="[getColor]" v-html="properties.text"></p>
</template>
  
<script>
export default {
  props: [
    "properties",
    "pageData"
  ],
  computed: {
    getColor() {
      return {
        color: this.properties.style,
        'pointer-events': 'none'
      }
    }
  }
};
</script>
  
  <style lang="scss">
  .backgroundSlogan {
    position: absolute;
    color: var(--light-grey);
    left: 105px;
    top: 42px;
    font-size: 25px;
    font-weight: 700;
  }
  </style>