<template>
    <button id="IdleToggleButton" @click="toggleIdleMode">
        <img src="@/assets/img/ui/idle-start.png" v-if="!idleActive">
        <img src="@/assets/img/ui/idle-pause.png" v-else>
    </button>
</template>
<script>
export default {
    methods: {
        toggleIdleMode() {
            this.$store.commit('toggleIdle')
        }
    },
    computed: {
        idleActive() {
            return this.$store.getters.getIdleState
        }
    }
}
</script>
<style lang="scss" scoped>
#IdleToggleButton {
    position: absolute;
    right: 120px;
    bottom: 42px;
}
</style>