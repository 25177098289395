<template>
  <section class="backgroundSlider">

    <ul class="backgroundSlider__content" :style="`transform: translateX(-${ currentSlideIndex * 100 }%)`">
      <li class="backgroundSlider__item" v-for="slide, i in properties.slides" :key="i">
        <div class="backgroundSlider__item-bg" aria-hidden="true">
          <video v-if="slide.backgroundVideo" :src="getMediaPath(slide.backgroundVideo.src)" autoplay loop muted disablepictureinpicture></video>
          <img v-else-if="slide.backgroundImage" :src="getMediaPath(slide.backgroundImage.src)">
          <div class="sceneGradient sceneGradient--top sceneGradient--blue"></div>
          <div class="sceneGradient sceneGradient--left sceneGradient--black"></div>
        </div>
        <backgroundTextBlock :properties="slide" :pageData="pageData" ></backgroundTextBlock>
      </li>
    </ul>

    <div class="backgroundSlider__nav" v-if="properties.slides.length > 1">
      <button class="backgroundSlider__nav-button back" aria-label="Previous" @click="prevSlide"></button>
      <span class="backgroundSlider__nav-dot" aria-hidden="true" :class="{'backgroundSlider__nav-dot--current': (currentSlideIndex === i)}" v-for="slide, i in properties.slides" :key="i" @click="goToSlide(i)"></span>
      <button class="backgroundSlider__nav-button next" aria-label="Next" @click="nextSlide"></button>
    </div>
  
  </section>
</template>

<script>
import backgroundTextBlock from "./backgroundTextBlock.vue"

export default {
  props: [
    "properties",
    "pageData"
  ],
  data() {
    return {
      selectedTab: null,
      currentSlideIndex: 0,
    }
  },
  components: {
    'backgroundTextBlock': backgroundTextBlock
  },
  methods: {
    getMediaPath(src) {
      return app.api.Utils.getMediaPath(src)
    },
    
    // navigation
    selectTab(tab) {
      tab !== this.selectedTab ? this.selectedTab = tab : this.selectedTab = null;
    },
    goToSlide(slideIndex) {
      this.currentSlideIndex = slideIndex;
    },
    prevSlide() {
			this.currentSlideIndex > 0 ? this.currentSlideIndex-- : this.currentSlideIndex = this.properties.slides.length - 1;
		},
		nextSlide() {
			this.currentSlideIndex+1 < this.properties.slides.length ? this.currentSlideIndex++ : this.currentSlideIndex = 0;
		},
  }
};
</script>

<style lang="scss">
.backgroundSlider {
  position: absolute;
  inset: 0;

  &__content {
    // width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 500ms ease;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  // single slide
  &__item {
    width: 100%;
    height: 100%;
    position: relative;
    flex-shrink: 0;

    &-bg {
      position: absolute;
      inset: 0;
      z-index: -1;
    }
  }

  // navigation
  &__nav {
    position: absolute;
    inset: auto 0 42px;
    display: flex;
    justify-content: center;

    &-button {
      cursor: pointer;
      width: 50px;
      height: 26px;
      background-position: center;
      background-repeat: no-repeat;

      &.back { background-image: url(@/assets/img/ui/slider-nav-left.svg); }
      &.next { background-image: url(@/assets/img/ui/slider-nav-right.svg); }
    }

    &-dot {
      cursor: pointer;
      width: 30px;
      height: 26px;
      position: relative;
      display: inline-block;

      &::after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 2px solid var(--light-grey);
        margin: 4px 6px;
      }

      &--current::after{ background-color: var(--light-grey);}
    }
  }
}
</style>