<template>
    <transition name="HotspotEntrance">
        <router-link class="Hotspot" v-show="ready" :to="hotspot.route" :class="[getHotspotMutatorClasses, getHotspotContentClass, getHotspotTypeClass]" :style="[getPosition]" @click="hotspotPressed">
            <div class="Hotspot__button" :style="[getAlternateHotspotIcon]"></div>
            <span v-html="getHotspotTitle"></span>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"            

            switch(this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel` 

                // more types can be added here through the backend hotspotType data value.

                default: 
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getHotspotContentClass() {
            if(this.hotspot.componentData) {
                return `Hotspot--${this.hotspot.componentData.type}`
            } else {
                return
            }
        },
        getAlternateHotspotIcon() {
            if(this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 44px;

.Hotspot {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    box-shadow: 0 3px 6px rgba(0,0,0, 0.16);

    .Hotspot__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $hotspot-height;
        width: $hotspot-height;
        position: absolute;
        top: 0;
        left: -32px;
        background-color: var(--main-color);
        background-image: url(@/assets/img/ui/plus.svg);
        background-size: 26px;
        background-position: center;
        background-repeat: no-repeat;
        border: 2px solid var(--light-grey);
        border-radius: 50%;
        z-index: 3;
    }
    
    span {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        font-weight: 500;
        color: var(--grey);
        font-size: 15px;
        height: $hotspot-height - 6px;
        background: var(--light-grey);
        padding: 0 26px;
        margin-top: 3px;
        z-index: 2;
    }

    &:hover {
        span { background: #eee; }
    }
    
    &:active {
        span { background: #ddd; }
    }
    
    &--flipped {
        flex-direction: row-reverse;
        
        span {
            padding: 0 36px 0 20px;
            margin-left: auto;
            margin-right: -22px;
        }
    }
    
    // Styles for specifc components that hotspot can open
    &--fullscreenVideo, &--fullscreenIframePlayer {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/play.svg);
        }
    }
    &--externalLink {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/globe.svg);
        }
    }
    &--mail {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/mail.svg);
        }
    }

    // Styles for specific hotspot types defined by getHotspotTypeClass()
    &--NoLabel {
        span {
            display: none;
        }
    }
}

/* entrance animation */
.HotspotEntrance-enter-active {
    transition: all 1s var(--tk-easeinout);
}
.HotspotEntrance-leave-active {
    transition: all 0.3s var(--tk-easeinout);
}
.HotspotEntrance-enter-from {
    transform: translateY(-300%);
    opacity: 0;
}
.HotspotEntrance-leave-to {
    opacity: 0;
}

</style>