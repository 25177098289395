<template>
    <h1 class="title" v-html="content.text"></h1>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">

.title {
    font-weight: 700;
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 35px;
}
</style>