<template>
    <article class="fullscreenImage" v-if="ready" @click.self="closeComponent">
      <div class="fullscreenImage__imgwrap">
        <img :src="imageUrl">
        <button class="fullscreenImage__close" @click="closeComponent"></button>
      </div>
    </article>
</template>

<script>


export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false
    }
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  computed: {
    imageUrl() {
      return app.api.Utils.getMediaPath(this.properties.image.src)
    }
  }
};
</script>

<style lang="scss">
.fullscreenImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  &__imgwrap {
    position: relative;
    height: calc(100% - 80px); 

    img {
      height: 100%;
      width: auto;
    }
  }
}

.fullscreenImage__close {
  position: absolute;
  right: 0;
  top: 0;
  height: 52px;
  width: 52px;
  background: var(--main-color);
  background-image: url(@/assets/img/ui/close.svg);
  background-position: center;
  background-repeat: no-repeat;
}
</style>