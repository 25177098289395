<template>
    <button id="BackButton" :class="{'Backbutton--inactive': onHomePage}" @click="navigateBack" aria-label="back">
        <img src="@/assets/img/ui/back-arrow.svg">
    </button>
</template>
<script>
export default {
    methods: {
        navigateBack() {
            app.api.PageManager.navigateToParentScene();
        }
    },
    computed: {
        onHomePage() {
            this.$route;
            return app.api.PageManager.onHomePage()
        }
    }
}
</script>
<style lang="scss" scoped>
    #BackButton {
        position: absolute;
        background: var(--secondary-color);
        left: 55px;
        bottom: 42px;
        width: 52px;
        height: 52px;
        transition: ease 0.2s opacity;

        img {
            margin-bottom: -3px;
            pointer-events: none;
        }
    }

    .Backbutton--inactive {
        opacity: 0;
        pointer-events: none !important;
    }
</style>