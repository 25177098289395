<template>
    <router-link class="Hotspot hiddenHotspot" :to="hotspot.route" :class="getHotspotClasses" :style="[getPosition, getSize]" @click="hotspotPressed"></router-link>
</template>

<script>
export default {
    props: ['hotspot'],
    methods: {
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getSize() {
            return {
                width: this.hotspot.data.hotspotSize.width,
                height: this.hotspot.data.hotspotSize.height
            }
        }
    }
}
</script>