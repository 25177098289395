<template>
    <button id="FullscreenButton" v-if="!onHomePage" :class="{'FullscreenButton--isFullscreen': isFullscreen}" @click="toggleFullscreen" aria-hidden="true">
        <img src="@/assets/img/ui/fullscreen.png" v-if="!isFullscreen">
        <img src="@/assets/img/ui/fullscreen-active.png" v-else>
    </button>
</template>
<script>
export default {
    methods: {
        toggleFullscreen() {
            app.api.Utils.toggleFullscreen();
        }
    },
    computed: {
        isFullscreen() {
            return this.$store.getters.isFullscreen
        }
    }
}
</script>
<style lang="scss" scoped>
    #FullscreenButton {
        position: absolute;
        right: 55px;
        bottom: 42px;
    }
</style>