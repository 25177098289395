<template>
   <div class="PageBackground" ref="pageBackground" :style="{ width: `${getMediaWidth <= 1920 ? 1920 : getMediaWidth}px` }" :class="getBackgroundClasses"
        :data-background-width="getMediaWidth">
        <video :src="backgroundVideo" ref="bgVideo" v-if="hasVideo" @loadedmetadata="measureVideoWidth" muted autoplay loop
            playsinline webkit-playsinline type="video/mp4" @contextmenu.prevent="" disablepictureinpicture></video>
        <img v-if="hasImage" :src="backgroundImage" :alt="background.image.name" @load="backgroundLoaded">
    </div>
</template>

<script>
export default {
    props: ["background", "hasContentComponentOpen", "scrollingBackground"],
    data() {
        return {
            videoWidth: 0
        }
    },
    methods: {
        async measureVideoWidth() {
            const videoElement = this.$refs.bgVideo;
            if (videoElement) {
                this.videoWidth = videoElement.videoWidth;
            }
            await this.$nextTick
            this.backgroundLoaded();
        },
        backgroundLoaded() {    
            window.dispatchEvent( new Event('backgroundLoaded') );
        }
    },
    computed: {
        hasVideo() {
            return Object.keys(this.background)[0] === 'video'
        },
        getMediaWidth() {
            if (this.hasImage) {
                this.$store.commit('setBackgroundWidth', this.background.image.width)
                return this.background.image.width;
            } else if (this.hasVideo && this.videoWidth > 0) {
                this.$store.commit('setBackgroundWidth', this.videoWidth)
                return this.videoWidth;
            }
        },
        backgroundVideo() {
            if (this.hasVideo) {
                return app.api.Utils.getMediaPath(this.background.video.src)
            } else {
                return null;
            }
        },
        hasImage() {
            return Object.keys(this.background)[0] === 'image'
        },
        backgroundImage() {
            if (this.hasImage) {
                return app.api.Utils.getMediaPath(this.background.image.src);

            } else {
                return null;
            }
        },
        getBackgroundClasses() {
            return {
                'PageBackground--scrollable': this.scrollingBackground,
                'PageBackground--scrolling': this.$store.getters.isBackgroundDragged
            }
        }
    },

};
</script>

<style lang="scss" scoped>
.PageBackground {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: -1;

    &>* {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
    }

    &--scrollable {
        cursor: grab;
    }

    &--scrolling {
        cursor: grabbing;
    }
}
</style>