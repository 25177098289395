<template>
  <transition name="toggleArticleComponent">
    <article class="Article" v-if="ready">
      <button class="Article__close" @click="closeComponent">
        <img src="@/assets/img/ui/close.svg">
      </button>
      <div>
        <component :is="getContentComponent(content)" v-for="(content, i) in properties.content" :key="content.nsAlias + '_' + i" :content="content"></component>
      </div>
    </article>
  </transition>
</template>

<script>
import { markRaw } from 'vue';

export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
        ready: false,
        contentComponents: {}
    }
  },
  created() {
    this.importArticleComponents();
  },
  mounted() {
      this.ready = true;
  },
  methods: {
    importArticleComponents() {
      // Use require.context to dynamically import components from the ./hotspot-components subfolder
      const componentContext = require.context('./article-components/', true, /\.vue$/);
      
      // Iterate over each component path and extract the component name
      componentContext.keys().forEach((componentPath) => {
          const componentName = componentPath.replace(/^.\/(.*)\.\w+$/, '$1');

          // Import the component and mark it as non-reactive using markRaw
          this.contentComponents[componentName] = markRaw(componentContext(componentPath).default);
      });
    },
    getContentComponent(content) {
      // Retrieve the corresponding component name from the "type" supplied by the Umbraco model.
      // If the component is not found, use the 'defaultHotspot' component
      const componentName = content.nsAlias;
      const component = this.contentComponents[componentName];

      if (!component) {
          console.log('%c No component found for type: ' + componentName, 'color: red;');
      }

      return component;
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  }
};
</script>

<style lang="scss">

.Article {
  position: absolute;
  left: 56px;
  bottom: 126px;
  width: 800px;
  height: 856px;
  text-align: left;
  background: #fff;
  color: var(--grey);
  font-size: 22px;
  line-height: 32px;

  &>div {
    overflow-y: auto;
    margin: 50px 50px 0 50px;
    height: calc(100% - 50px);
    padding: 0 74px 50px 0;
  }

  &__close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 52px;
    height: 52px;
    background-color: var(--main-color);
  }
}

.toggleArticleComponent-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleArticleComponent-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleArticleComponent-enter-from {
  transform: translateX(-40px);
  opacity: 0;
}

.toggleArticleComponent-leave-to {
    opacity: 0;
}
</style>