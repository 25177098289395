<template>
  <article class="backgroundTextBlock" :style="[getTextStyle]">
    <div class="backgroundTextBlock__heading" v-html="properties.heading"></div>
    <div class="backgroundTextBlock__text-below-heading" v-html="properties.textBelowHeading"></div>
    <div class="backgroundTextBlock__contact-person-name" v-html="properties.contactPersonName"></div>
    <div class="backgroundTextBlock__contact-person-text" v-html="properties.contactPersonText"></div>
  </article>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData"
  ],
  computed: {
    getTextStyle() {
      return {
        'color': this.properties.textColor,
        'pointer-events': this.properties.allowSelection === false ? 'none' : 'all'
      }
    }
  }
};
</script>

<style lang="scss">
.backgroundTextBlock {
  position: absolute;
  left: 105px;
  top: 180px;
  bottom: 120px;
  color: #fff;
  text-shadow: rgba(122,122,12,.15) 0 0 8px;
  text-align: left;

  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 750px;

  &__heading {
    font-weight: bold;
    font-size: 80px;
    line-height: 90px;
    margin-top: auto;
    margin-bottom: 40px;
  }

  &__text-below-heading {
    font-size: 30px;
    line-height: 48px;
    font-weight: 300;
    margin-bottom: auto;
  }

  &__contact-person-name {
    font-size: 50px;
    line-height: 48px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: 14px;
  }

  &__contact-person-text {
    font-size: 30px;
    line-height: 48px;
    font-weight: 300;
    margin-bottom: 80px;

    a {
      color: inherit;
      text-decoration: none;
      pointer-events: auto;
      &:hover { text-decoration: underline; }
    }
  }

  p:last-child { margin-bottom: 0; }

}
</style>